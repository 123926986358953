<template>
  <v-slide-group multiple show-arrows height="80">
    <slot></slot>
    <v-slide-item>
      <v-text-field class="mt-6 mx-2" @input="$store.commit('tickets/filter/setFilter', {filter:'statut', value: 'all' })"
                    label="Identifiant du ticket" type="number" v-model="search_id"></v-text-field>
    </v-slide-item>
    <v-slide-item>
      <v-text-field class="mt-6 mx-2" label="Nom" v-model="search_name"></v-text-field>
    </v-slide-item>
    <v-slide-item>
      <v-select label="Statut"
                :items="status_filter_items"
                item-text="label"
                item-value="value"
                class="mt-6 mx-2"
                :value="selectedStatut"
                @change="$store.commit('tickets/filter/setFilter', {filter:'statut', value: $event })"></v-select>
    </v-slide-item>
    <v-slide-item>
      <v-select label="Demandeur"
                :items="demandeurs"
                item-text="fullname"
                item-value="username"
                class="mt-6 mx-2"
                :value="selectedDemandeur"
                @change="$store.commit('tickets/filter/setFilter', {filter:'demandeur', value: $event })"></v-select>
    </v-slide-item>
    <v-slide-item>
      <v-select label="Priorité"
                :items="priority_filter"
                item-text="label"
                item-value="value"
                class="mt-6 mx-2"
                :value="selectedPriority"
                @change="$store.commit('tickets/filter/setFilter', {filter:'priority', value: $event })"></v-select>
    </v-slide-item>
    <v-slide-item>
      <div>
        <SiteTreeSelector v-model="selected_site_tree"/>
      </div>
    </v-slide-item>
    <v-slide-item class="mt-5">
      <v-btn color="red" @click="$store.dispatch('tickets/filter/resetFilters')" text>
        <v-icon color="red">mdi-trash-can-outline</v-icon>
        Effacer les filtres
      </v-btn>
    </v-slide-item>
  </v-slide-group>
</template>
<script>
import {mapGetters} from "vuex";
import SiteTreeSelector from "@/views/SiteTreeSelector";

export default {
  name: 'TicketFilter',
  components: {SiteTreeSelector},
  async mounted() {
    await this.$store.dispatch("tickets/filter/resetFilters");
    let states = this.status_filter_items.map(item => item.value);
    let state = this.$route.query.state;
    if (state && states.indexOf(state) !== -1) {
      this.$store.commit('tickets/filter/setFilter', {filter: 'statut', value: state});
    }
    let priorities = this.priority_filter.map(item => item.value);
    let priority = this.$route.query.priority;
    if (priority && priorities.indexOf(priority) !== -1) {
      this.$store.commit('tickets/filter/setFilter', {filter: 'priority', value: priority});
    }
    if (this.$store.getters["sites/allSites"].length === 0) {
      await this.$store.dispatch("sites/fetchSites");
    }
  },
  data: function () {
    return {
      status_filter_items: [
        {"value": "", "label": ""},
        {"value": "all", "label": "Tous les tickets"},
        {"value": "waiting", "label": "En attente de réponse"},
        {"value": "ready", "label": "Assigné"},
        {"value": "closed", "label": "Clôturé"},
        {"value": "unassigned", label: "Non assigné"},
        {"value": "paused", label: "En suspens"},
        {"value": "processed", label: "Traité"}
      ],
      priority_filter: [
        {"value": "", "label": ""},
        {"value": "low", "label": "Basse"},
        {"value": "medium", "label": "Moyenne"},
        {"value": "high", "label": "Élevée"},
      ],
    };
  },
  methods: {
    site_name(site) {
      let siteObject = site;
      if (siteObject === null) return "Non défini";
      let siteText = siteObject.name;
      if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
      }
      if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
      }
      return siteText;
    }
  },
  computed: {
    search_id: {
      get() {
        return this.$store.getters["tickets/filter/getSearchId"];
      },
      set(value) {
        return this.$store.commit("tickets/filter/setSearchId", value)
      }
    },
    search_name: {
      get() {
        return this.$store.getters["tickets/filter/getSearchName"];
      },
      set(value) {
        return this.$store.commit("tickets/filter/setSearchName", value)
      }
    },
    sites_select() {
      return [
          {value: "", name: "Tous les sites"},
        ...this.$store.getters["sites/allSites"]
            .map(site => ({"name": this.site_name(site), "value": this.site_name(site)}))
      ];
    },
    ...mapGetters("tickets/filter", [
      "selectedDemandeur",
      "selectedPriority",
      "selectedStatut",
    ]),
    ...mapGetters("tickets", [
        "demandeurs"
    ]),
    selected_site_tree: {
      get() {
        return this.$store.getters["tickets/filter/getSelectedSiteTree"];
      },
      set(value) {
        return this.$store.commit("tickets/filter/setSelectedSiteTree", value)
      }
    },


  }
}
</script>

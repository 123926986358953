<template>
  <v-data-iterator class="pl-4 pt-4" :loading="!loadingTickets" loading-text="Chargement des tickets en cours..."
                   no-data-text="Aucun nouveau ticket." :items="$store.getters['tickets/filter/getFilteredTickets']"
                   item-key="id" :items-per-page="5">
    <template v-slot:default="{ items }">
      <v-card @click="setTicket(ticket)" outlined elevation="0" v-for="ticket in items" :key="ticket.id">
        <v-row justify="space-between" no-gutters class="pl-3 pt-3">
          <v-col class="pa-0 ma-0">
            <h3 class="primary--text"><span class="orange--text">#{{ ticket.id }}</span> {{ ticket.objet }}</h3>
            <p class="caption mb-0" v-if="ticket.created_by !== null">De : {{ ticket.created_by.first_name + " " + ticket.created_by.last_name }}</p>
            <p class="caption mb-0" v-else>De : Utilisateur supprimé</p>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-chip class="mr-2" v-if="ticket.state === 'waiting'" color="green">
              <span class="white--text">En attente de réponse</span>
            </v-chip>
            <v-chip class="mr-2" v-if="ticket.state === 'ready'" color="green">
              <span class="white--text">Assigné</span>
            </v-chip>
            <v-chip class="mr-2" v-if="ticket.state === 'closed'" color="grey">
              <span class="white--text">Clôturé</span>
            </v-chip>
            <v-chip class="mr-2" v-if="ticket.state === 'planned'" color="#FDD835">
              <span class="white--text">Programmé</span>
            </v-chip>
            <v-chip class="mr-2" v-if="ticket.state === 'ongoing'" color="#FDD835">
              <span class="white--text">En cours</span>
            </v-chip>
            <v-chip class="mr-2" v-if="ticket.state === 'finished'" color="blue">
              <span class="white--text">Réalisé</span>
            </v-chip>
            <v-chip class="mr-2" v-if="ticket.state === 'unassigned'" color="red">
              <span class="white--text">Non assigné</span>
            </v-chip>
            <v-chip class="mr-2" v-if="ticket.state === 'processed'" color="primary">
              <span class="white--text">Traité</span>
            </v-chip>
            <v-chip class="mr-2 brown darken-2" v-if="ticket.state === 'paused'">
              <span class="white--text">En suspens</span>
            </v-chip>
            <v-chip v-if="ticket.priority === 'high'" color="red" class="white--text">Élevée</v-chip>
            <v-chip v-if="ticket.priority === 'medium'" color="orange" class="white--text">Moyenne</v-chip>
            <v-chip v-if="ticket.priority === 'low'" color="green" class="white--text">Basse</v-chip>

          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">

          <v-col class="pa-0" cols="12">

          </v-col>
          <v-col class="pa-0" md="9">
            <span>{{ formatDate(ticket.date_creation) }}</span>
          </v-col>
          <v-col cols="12" class="pa-0 ma-0" v-if="ticket.asset !== null">
            <span class="grey--text"><v-icon>mdi-hammer-wrench</v-icon> {{ticket.asset.name}}</span><br>
            <span v-if="ticket.asset.site !== null" class="grey--text">
              <v-icon left>mdi-map-marker</v-icon>
              <span>{{ ticket.asset.site.name }}</span>
              <span v-if="ticket.asset.site.parent !== null">, {{ ticket.asset.site.parent }}</span>
              <span v-if="ticket.asset.site.top !== null">, {{ ticket.asset.site.top }}</span>
              <span v-if="ticket.asset === null" class="font-italic grey--text">Non défini</span>
            </span>
            <span v-if="ticket.parent" class="grey--text"><br><v-icon>mdi-ticket</v-icon> Sous-ticket de Ticket #{{ticket.parent.id}} : {{ticket.parent.objet}}</span>
            <span v-if="ticket.tickets.length > 0" class="black--text"><br><v-icon>mdi-information</v-icon> {{ ticket.tickets.length }} sous-ticket(s)
              - {{getActiveTickets(ticket)}} sous-ticket(s) ouvert(s)</span>
          </v-col>
        </v-row>
      </v-card>
    </template>
  </v-data-iterator>
</template>
<script>
import dayjs from "dayjs";

export default {
  name: 'TicketList',
  props: {
    'loadingTickets': Boolean,
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD MMMM YYYY à HH:mm");
    },
    setTicket(ticket) {
      this.$store.commit("tickets/setSelectedTicket", ticket);
      const path = `/tickets/${ticket.id}`;
      if (this.$route.path !== path) this.$router.push(path);
      this.$vuetify.goTo(0);
    },
    getActiveTickets(ticket) {
      if(ticket.tickets.length > 0) {
        let count = 0
        for(let t of ticket.tickets) {
          if(["finished", "closed", "paused"].indexOf(t.state) === -1) {
            count += 1;
          }
          console.log("On arrive la avec " + count)
        }
        return count;
      }
      return 0;
    },
  }
}
</script>
<style>
.text--primary {
  color: #208774;
}
</style>